export const QUESTION = {
    jap: {
        title1: "本日は東屋にご滞在いただきありがとうございます。",
        title2: "東屋ではこれからベトナム1番のサービスと快適な空間を提供したいと思っております。",
        title3: "ぜひお客様のご意見、ご感想をお聞かせください。",
        questionroomNumber: {
            title: "お部屋の番号を教えていただきませんか?",
            answer: "",
        },
        questionbranchID: {
            title: "滞在している支店",
            answer: "",
        },
        question1: {
            title: "滞在期間をお聞かせください。",
            answer: [
                "1泊",
                "2~6泊",
                "1~4週間",
                "1ヶ月以上",
            ]
        },
        question2: {
            title: "お客様の年齢をお聞かせください。",
            answer: [
                "内緒", 
                "20歳以下",
                "20代",
                "30代",
                "40代",
                "50代以上",
            ]
        },
        question3: {
            title: "東屋ベトナムへの来店頻度をお聞かせ下さい。",
            answer: [
                "初めて",
                "2回目",
                "3回目",
                "それ以上",
            ]
        },
        question4: {
            title: "当店をお知りになったきっかけを教えてください.",
            // answer2 : [
            //     "東屋プノンペン",
            //     "店頭の看板",
            //     "広告", //"LIV"
            //     "雑誌", // ベッター
            //     "ポステ",
            //     "東屋ウェブ",
            //     "会社手配",
            //     "こ紹介",
            //     "それ以外", //それ以上
            // ],
            answer : [
                "会社手配",
                "ご紹介",
                "東屋ウェブ",
                "店頭の看板",
                "グーグル",
                "SNS",
                "ポステ",
                "雑誌",
                "それ以外",
            ]
        },
        question5: {
            title: "各サービスの満足度を採点してください.", //当店を紡刻りになったきっがけ形を散えでく<だきい.
            title2: "* サービスを利用されていない項目は未記人でお願いします",
            answer: [
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
            ],
        },
        question6: {
            title: "ホテルをお選びになる点で、特に重視されることをお聞かせください。(複数回答可)",
            answer : [
                "価格",
                "立地",
                "露天風呂",
                "清潔感",
                "朝食の充実",
                "備品、アメニティー",
                "スタッフの対応",
                "日本語テレビ",
                "ネット環境",
                "マッサージ併設",
                "日本語対応",
                "日本食レストラン併設",
                "その他",
            ]
        },
        question7: {
            title: "次のご利用について。",
            answer: [
                "また来たい",
                "分からない",
                "来ない",
            ]
        },
        question8: {
            title: "その他、ご意見、改善点があればお願いします。",
            answer: ""
        }
    },
    eng : {
        title1: "Thank you for your staying at our Azumaya Hotel. ",
        title2: "We hope to listen your opinions to provide you the best service.",
        title3: "",
        questionroomNumber: {
            title: "Your room number?",
            answer: "",
        },
        questionbranchID: {
            title: "The branch Azumaya you are staying at?",
            answer: "",
        },
        question1: {
            title: "Duration of your staying.",
            answer: [
                "1 night",
                "2~6 nights",
                "1~4 Weeks",
                "More than 1 month",
            ]
        },
        question2: {
            title: "Your age",
            answer: [
                "Secret",
                "Under 20",
                "20s",
                "30s",
                "40s",
                "Over 50s",
            ]
        },
        question3: {
            title: "How many times have you stayed at Azumaya Hotel.",
            answer: [
                "First time",
                "Second time",
                "Third time",
                "More than 3 times",
            ]
        },
        question4: {
            title: "How did you know AZ.",
            // answer : [
            //     "AZ Phnom Penh ",
            //     "Advertisement", //  "LIV",
            //     "Magazine",  //VETTER
            //     "POSTE",
            //     "AZ Web",
            //     "Company",
            //     "Introduction",
            //     "AZ Signboard",
            //     "Other",
            // ],
            answer : [
                "Company",
                "Introduction",
                "AZ Web",
                "AZ Signboard",
                "Google",
                "SNS",
                "POSTE",
                "Magazine",
                "Other",
            ]
        },
        question5: {
            title: "Please evaluate our services.", //当店を紡刻りになったきっがけ形を散えでく<だきい.
            title2: "*lf you don't use some services, please don't evaluate",
            answer: [
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
                {
                    answer: 0,
                    subAnswer : 0,
                    nameSubAnswer: ""
                },
            ],
        },
        question6: {
            title: "Which are your important things to choose a hotel for staying (multiple choices)",
            answer : [
                "Price",                                                     
                "Location",                                   
                "Open air hot tub",
                "Rom cleanliness",                                 
                "Breakfast",                                   
                "Facility/Amenity",
                "Staff's attitude",                                   
                "Japanese TV",                              
                "Internet",
                "Massage",                                            
                "Hospitality JP",           
                "Japanese Restaurant",
                "Other",
            ]
        },
        question7: {
            title: "Will you come to Azumaya again?",
            answer: [
                "yes",
                "Do not know",
                "No",
            ]
        },
        question8: {
            title: "Do you have any suggestions?",
            answer: ""
        }
    }
}

export const QUESTION_AZPP = {
    jap: {
        title2: "東屋ではこれからカンボジア1番のサービスと快適な空間を提供したいと思っております。",
        question3: {
            title: "東屋プノンペンへの来店頻度をお聞かせ下さい。",
            answer: [
                "初めて",
                "2回目",
                "3回目",
                "それ以上",
            ]
        },
        question4: {
            title: "当店をお知りになったきっかけを教えてください.",
            answer : [
                // "東屋ベトナム",
                // "店頭の看板",
                // "広告", //"LIV"
                // "雑誌", // ベッター
                // "ポステ",
                // "東屋ウェブ",
                // "会社手配",
                // "こ紹介",
                // "それ以外", //それ以上
                "会社手配",
                "ご紹介",
                "東屋ウェブ",
                "店頭の看板",
                "グーグル",
                "SNS",
                "ポステ",
                "雑誌",
                "それ以外",
            ]
    }

    },
    eng: {
        title2: "We hope to listen your opinions to provide you the best service.",
        question3: {
            title: "How many times have you stayed at Azumaya Hotel.",
            answer: [
                "First time",
                "Second time",
                "Third time",
                "More than 3 times",
            ]
        },
        question4: {
            title: "How did you know AZ.",
            answer : [
                // "AZ Viet Nam",
                // "Advertisement", //  "LIV",
                // "Magazine",  //VETTER
                // "POSTE",
                // "AZ Web",
                // "Company",
                // "Introduction",
                // "AZ Signboard",
                // "Other",
                "Company",
                "Introduction",
                "AZ Web",
                "AZ Signboard",
                "Google",
                "SNS",
                "POSTE",
                "Magazine",
                "Other",
            ]
        },
    }
}
export const QUESTION5_SUB_ANSWER = {
    jap: {
        5: [
            "インターネット",
            "家具",
            "テレビ",
            "バスルーム",
            "エアコン",
            "マットレス",
            "その他",
        ],
        6: [
            "部屋",
            "におい",
            "その他",
            "バスルーム",
            "リネン",
        ],
        8: [
            "使用しない",
            "清潔さ",
            "風呂の温度",
            "顧客が多い",
            "その他",
        ],
        9: [
           "使用しない",
           "マツサージの質",
           "ルスタッフの態度",
           "屋内の環境",
           "その他",
        ],
        title:"改善点:"
    },
    eng: {
        5: [
            "Internet",
            "Furniture",
            "TV",                               
            "Bathroom",                                               
            "Mattress",              
            "Air conditioner",
            "Other",
        ],
        6: [
            "Room",
            "Bathroom",
            "Smell",                   
            "Linen",
            "Other",

        ],
        8: [
            "Not use",                      
            "Temperature", 
            "Cleanliness",                          
            "Many customer",  
            "Other", 
        ],
        9: [
            "Not use",
            "Quality",
            "Attitude",  
            "Environment",
            "Other",
        ],
        title:"Improve point : "
    }
}

export const QUESTION5_TITLE_COL = {
    jap: [
        "①客室料金",
        "②受付の接客",
        "③受付以外の接客",
        "④朝食の味",
        "⑤朝食の提供速度",
        "⑥客室の設備", //
        "⑦客室の清潔感", //
        "⑧客室以外の清潔感", //⑧お部屋以外の清潔感
        "⑨露天風呂",
        "⑩マッサージ", //フットマッサージ
    ],
    eng: [
        "1 Price",
        "2 Reception's Attitude",
        "3 Other Attitude",
        "4 Breakfast Taste",
        "5 Breakfast Speed",
        "6 Room Equipment",
        "7 Room Cleanliness",
        "8 Others Cleanliness", 
        "9 Open Hot Bath",
        "10 Massage",
    ],
}

export const QUESTION5_TITLE_ROW = {
    jap: [
        "とても<br/>悪い", //とても悪い
        "悪い",
        "普通",
        "良い",
        "とても<br />良い", //とても良い
    ],
    eng: [
        "Very bad", 
        "Bad",
        "Normal",
        "Good",
        "Very good",
    ]
}

export const COMPLETE = {
    jap: "アンケートに協力いただき、ありがとうございます。",
    eng: "Thank you for Survey."
}